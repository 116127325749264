<template>
  <div>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <base-img
        position="left"
        :src="require('@/assets/orendaLogo.jpeg')"
        class="mr-3 hidden-xs-only"
        contain
        max-height="60"
      />

      <v-spacer />

      <div>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab
            v-for="({ title, name }, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
            >{{ title }}</v-tab
          >
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
import accountMixin from "@/mixins/accounts.js";
const anonymousItems = [
  { title: "Home", name: "Home" },
  { title: "About", name: "About" },
  { title: "Legal Guidelines", name: "Legal" },
  { title: "FAQ", name: "FAQ" },
  { title: "Contact", name: "Contact" },
];
const adminItems = [{ title: "Admin", name: "Admin" }];
const memberItems = [
  { title: "Dashboard", name: "Dashboard" },
  { title: "Profile", name: "Profile" },
  { title: "Card", name: "Card" },
  { title: "Loans", name: "Loans" },
];

const conditional_items = [{ title: "Application", name: "LoanApplication" }];
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },
  mixins: [accountMixin],
  data: () => ({
    drawer: null,
    items: [],
  }),
  computed: {
    anyCurrentLoan() {
      return this.$store.getters["loan/anyCurrentLoan"];
    },
    isAdmin() {
      return this.$store.state.profile.isAdmin;
    },
    authorized() {
      return this.$store.getters["account/authorized"];
    },
  },
  watch: {
    authorized: function (val) {
      console.log("authorized----", val);
      this.loadItems(val, this.isAdmin);
    },
    isAdmin: function (val) {
      console.log("isAdmin----", val);
      this.loadItems(this.authorized, val);
    },
  },
  mounted() {
    this.loadItems(this.authorized, this.isAdmin);
  },
  methods: {
    loadItems(authorized, isAdmin) {
      console.log("is production", this.$orendaIsProduction);
      if (authorized) {
        let allowedLinks = memberItems;
        if (!(this.$orendaIsProduction && this.anyCurrentLoan)) {
          allowedLinks = [...memberItems, ...conditional_items];
        }
        this.items = isAdmin ? [...allowedLinks, ...adminItems] : allowedLinks;
      } else {
        this.items = anonymousItems;
      }
    },
  },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
